.AutomationRoiCalculator {
  padding: 40px;
  background: rgba(0,0,0,0.8);
  border-radius: 20px;
}
.AutomationRoiCalculator .btn {
  width: 100%;
  background-color: white;
  border-color: white;
  color: black;
  box-shadow: 0 0 5px white;
  border-radius: 35px;
  padding: 14px;
  margin-top: 1em;
}
.AutomationRoiCalculator .btn:hover,
.AutomationRoiCalculator .btn:focus,
.AutomationRoiCalculator .btn:active {
  background-color: black;
  color: white;
}
